<template>
    <div class="content-wrapper">
        <div class="content-lookup">
            <div class="lookup-left">
                <ul class="lookup-condition">
                    <li class="field">
                        <div class="title">사용자</div>
                        <ul class="content">
                            <li class="item">
                                <input-text
                                        v-model="search.userName"
                                        placeholder="사용자명"
                                />
                            </li>
                        </ul>
                    </li>
                    <li class="field">
                        <div class="title">변경 일자</div>
                        <ul class="content">
                            <li class="item dateRange">
                                <input-date-range
                                        type="lookup-condition"
                                        v-model="search.dateRange"
                                />
                            </li>
                        </ul>
                    </li>


                </ul>
              <div class="lookup-lookup">
                <erp-button
                    button-div="GET"
                    :is-shortcut-button="true"
                    v-on:click.native="fetchErpPasswordChangeHist"
                >
                  조회
                </erp-button>
              </div>
            </div>
        </div>

        <div class="content-body">
            <report-view
                    ref="reportViewComponent"
                    :isPopup="true"
            />
            <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                    <div class="section-header">
                        <div class="header-left">
                            <div class="header-title">ERP 비밀번호 변경 이력</div>
                            <div class="header-caption">[{{ count }}건]</div>
                        </div>
                        <div class="header-right">
                            <ul class="header-button">
                                <li class="print">
                                  <erp-button
                                      button-div="PRINT"
                                      @click.native="onPrintClick"
                                      :is-shortcut-button="true"
                                  >인쇄
                                  </erp-button>
                                </li>
                                <li class="print">
                                  <erp-button
                                      button-div="FILE"
                                      @click.native="onExcelClick"
                                  >
                                    Excel
                                  </erp-button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="section-body">
                        <div class="body-grid">
                            <ejs-grid-wrapper
                                    ref="grid"
                                    :provides="grid"
                                    :columns="columns"
                                    :dataSource="erpPasswordChgData"
                                    :pageSettings="pageSettings"
                                    :allowEditing="false"
                                    :allowResizing="true"
                                    :allowExcelExport="true"
                                    :allowPaging="true"
                                    @actionComplete="gridComplete"
                            />
                        </div>
                    </div>
                </section>
            </article>
        </div>
    </div>

</template>

<script>
import moment from "moment";
import ReportView from "../../components/common/report/ReportView.vue";
import ejsGridWrapper from "../../components/common/EjsGridWrapper.vue";
import InputText from "../../components/common/text/InputText.vue";
import inputDateRange  from "../../components/common/datetime/InputDateRange.vue";
import commonMixin from "../../views/layout/mixin/commonMixin";
import GolfErpAPI from "../../api/v2/GolfErpAPI";
import messagePopupDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import {ExcelExport, ForeignKey, Page, Resize} from "@syncfusion/ej2-vue-grids";
import { numberWithCommas } from "@/utils/number";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
    name: "ErpPasswordChangeHist",
    mixins: [commonMixin, messagePopupDialogMixin],
    props: {

    },
    components: {
        ReportView
        ,ejsGridWrapper
        ,inputDateRange
        ,InputText
        ,ErpButton
    },
    created() {
    },
    mounted() {
    },
    computed: {
    },
    data () {
        return {
            count: 0,
            isAddPrintPopupOpen: false,
            search: {
                userName: '',
                dateRange: {
                    from: moment().startOf('month').format('YYYY-MM-DD'),
                    to: moment().format('YYYY-MM-DD'),
                },
            },
            erpPasswordChgData : [],
            grid: [
                Resize,
                Page,
                ExcelExport,
                ForeignKey,
            ],
            columns: [
                {field: 'logId',        textAlign: 'center',    width: 100,   visible: false,   type: 'number',   allowSorting: true,   headerText: 'logId'   },
                {field: 'userName',     textAlign: 'center',    width: 100,   visible: true,    type: 'string',   allowSorting: true,   headerText: '사용자명'  },
                {field: 'userId',       textAlign: 'center',    width: 100,   visible: true,    type: 'number',   allowSorting: true,   headerText: '사용자ID' },
                {field: 'changeDate',   textAlign: 'center',    width: 100,   visible: true,    type: 'string',   allowSorting: true,   headerText: '변경일자'  },
                {field: 'changePwd',    textAlign: 'center',    width: 100,   visible: false,   type: 'string',   allowSorting: true,   headerText: '변경번호'  },
                {field: 'insertName',   textAlign: 'center',    width: 100,   visible: true,    type: 'string',   allowSorting: true,   headerText: '등록자'   },
                {field: 'insertId',     textAlign: 'center',    width: 100,   visible: false,   type: 'number',   allowSorting: true,   headerText: '등록자'   },
                {field: 'insertDt',     textAlign: 'center',    width: 130,   visible: true,    type: 'string',   allowSorting: true,   headerText: '등록일자'  },
                {field: 'updateId',     textAlign: 'center',    width: 100,   visible: true,    type: 'number',   allowSorting: true,   headerText: '수정자'   },
                {field: 'updateDt',     textAlign: 'center',    width: 130,   visible: true,    type: 'string',   allowSorting: true,   headerText: '수정일자'  },
            ],
            pageSettings: {pageSize: 50}
        };
    },
    methods: {
        async fetchErpPasswordChangeHist() {
            let params = {
                fromDate: this.search.dateRange.from,
                toDate: this.search.dateRange.to,
                userName: this.search.userName
            };
            await GolfErpAPI.getErpPasswordChangeHist(params).then(res => {
                if (res) {
                    res.forEach(e => {
                        e.changeDate = moment(e.changeDate).format('YYYY-MM-DD');
                    });
                    this.erpPasswordChgData = res;
                }
            });
        },
       /* replaceDate(date) {
            return moment(date).format('YYYYMMDD');
        },*/
        onPrintClick() {
            const result = this.$refs.grid.getGridBatchData();

            if (result.length < 1) {
                return this.errorToast(this.$t('report.popupMessage.noData'));
            }

            const searchOptionsList = [
                {
                    key : '사용자',
                    value : this.search.userName
                },
                {
                    key: '조회일자',
                    value: `${this.search.dateRange.from} ~ ${this.search.dateRange.to}`
                }
            ];

            const searchOptions = searchOptionsList
                .filter(item => !!item.value)
                .map(item => item.key + ': ' + item.value)
                .join(', ');

            const reportData = {
                reportJson: {
                    jsonData: result,
                    username: this.search.userName,
                    uniqueCode: this.$options.name,
                    searchOptions,
                },
            };
            this.$refs.reportViewComponent.postReport(reportData, this.$options.name);
        },
        gridComplete() {
            this.count = numberWithCommas(
                this.$refs.grid?.getGridBatchCount() || 0
            );
        },
        onExcelClick() {
            this.$refs.grid.excelExport();
        },
    },

};
</script>

<style scoped>
</style>